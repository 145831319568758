/*
Version: 1.0
Author: Symlink Technology
Author URI: https://www.symlinktech.com/.
*/
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Open+Sans:wght@300;400;500;600;700;800&family=Roboto+Condensed:wght@300;400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap');
/*
font-family: 'Lato', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
*/


/* ********|| INITIALIZATION STARTS ||******** */
body, html { width: 100%; line-height:18px; margin:0 auto !important;padding:0 !important;font-family: 'Lato', sans-serif; font-weight: 300; -webkit-font-smoothing: subpixel-antialiased;text-shadow: 1px 1px 1px rgba(0,0,0,0.004);font-size: 14px;  color: var(--textDarkColor); background: var(--bgColor);position: relative; z-index: 0; }


*:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important;}
a {text-decoration: none ;}
a:hover{text-decoration: none;-webkit-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -o-transition: all 0.3s ease; -ms-transition: all 0.3s ease;transition: all 0.3s ease;}
a:focus{ outline: none;text-decoration: none;}
input:focus, label:focus{outline: none !important;outline-offset: none !important;outline-offset: 0 !important;}
/* ********|| INITIALIZATION ENDS ||******** */


/* ********|| PSEUDO ELEMENTS STARTS ||******** */
::selection{ background-color:var(--color01); color:var(--textColor)}
::-moz-selection{ background-color:var(--color01); color:var(--textColor)}
::-webkit-selection{ background-color:var(--color01); color:var(--textColor)}

:-webkit-focus { outline: none !important; }
:-moz-focus { outline: none !important; }
:focus { outline: none !important; }

select:-webkit-focusring { color: var(--textDarkColor) !important;text-shadow: 0 0 0 var(--textDarkColor);}
select:-moz-focusring {color: var(--textDarkColor) !important;text-shadow: 0 0 0 var(--textDarkColor);}
select:focusring {color: var(--textDarkColor) !important; text-shadow: 0 0 0 var(--textDarkColor);}

::input-placeholder{ color:var(--textColor) !important;}
::-moz-input-placeholder{ color:var(--textColor) !important;}
::-webkit-input-placeholder{ color:var(--textColor) !important;}

/*::-webkit-scrollbar-track{	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);box-shadow: inset 0 0 6px rgba(0,0,0,0.1);background-color: var(--textMedColor);}
::-webkit-scrollbar{width: 5px;height: 5px;background-color: var(--textMedColor);}
::-webkit-scrollbar-thumb{background-color: var(--secondaryColorAltLight);}*/
/* ********|| PSEUDO ELEMENTS ENDS ||******** */


/* ********|| ADJUSTMENT CLASSES STARTS ||******** */
*,::after,::before{box-sizing: border-box;}
a{color:var(--color03); transition: all 0.3s ease;}
a:hover, a:focus{color:var(--color03);}
p { margin: 0 0 10px; }
hr{margin-top: 20px; margin-bottom: 20px; border: 0; border-top: 1px solid rgba(0,0,0,0.1);}
.h-40{height:40px !important;} 
.w-40{width:40px !important;} 

.or-separator{margin: 30px 0; display: flex; justify-content: center; align-self: center;position: relative; width: 100%; border-top:1px solid rgba(0,0,0,0.1);}
.or-separator span{display: flex;justify-content: center;align-items: center;position: absolute;width: 60px;height: 40px;background-color: var(--white);text-transform: uppercase;font-weight: 600;top: 50%;left: 50%;transform: translate(-50%,-50%);}

/* ********|| ADJUSTMENT CLASSES ENDS ||******** */

.table td{font-weight: 400;border-top: 1px solid rgba(0,0,0,0.1); vertical-align: middle;}
.table th{border-top: 1px solid rgba(0,0,0,0.1);border-bottom: 2px solid rgba(0,0,0,0.15);}
.table tr:last-child td{border-bottom: 1px solid rgba(0,0,0,0.1);}
.table hr{margin-top: 5px; margin-bottom: 5px; border: 0; border-top: 1px solid rgba(0,0,0,0.15);}

section{position:relative;}
.form-content{position: relative;background: var(--textColor);padding: 20px; border-radius: 6px;}
.form-group{position:relative; margin: 0 0 20px;}
.control-label{font-size: 12px;font-weight: 600;margin: 0 0 -10px;color: var(--textLightColor);text-transform: uppercase;}

.form-control{ border-radius: 0;box-shadow: none;border:0;border-bottom: 2px solid var(--textDarkColor);color: var(--textDarkColor);background-color: transparent;min-width: 50px;min-height: 40px;width: 100%;padding: 6px 0;font-family: inherit;font-size: 14px; font-weight: 600;}
.form-control:focus{ border-color:var(--color03);box-shadow: none;background-color: transparent;color: var(--textDarkColor);}
.form-control::-webkit-input-placeholder{ color: var(--textDarkColor) !important;}
.form-control:-moz-placeholder{color:var(--textDarkColor) !important;opacity:  1 !important;}
.form-control::-moz-placeholder{color:var(--textDarkColor) !important; opacity:1 !important;}
.form-control:-ms-input-placeholder{color:var(--textDarkColor) !important;}
.form-control::-ms-input-placeholder{color:var(--textDarkColor) !important;}
textarea.form-control{ resize:none; min-height: 100px;}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{ background-color: var(--textColor); opacity: 0.7; cursor: not-allowed;}
.form-control.radioAction{ min-height: 25px; min-width: 25px; }
.form-action{display:flex; justify-content: flex-end;}
.form-note{font-size: 12px;font-weight: 500;color: var(--textLightColor);line-height: 1.5;}

.form-checkbox{position: relative; overflow: hidden;}
.form-checkbox .form-checkbox-input{position: absolute; top: 0; left: 0; width: 100%; height: 100%; cursor: pointer; opacity: 0; z-index: 1;}
.form-checkbox .form-checkbox-label{position: relative;border-radius: 0;box-shadow: none;border: 0;border-bottom: 2px solid var(--textDarkColor);color: var(--textDarkColor);background-color: transparent;min-width: 50px;min-height: 40px;width: 100%;font-size: 14px;text-transform: uppercase;font-weight: 600; display: flex; align-items: center; padding-left: 30px;}
.form-checkbox .form-checkbox-label:before{content:'';position: absolute;width: 20px;height: 20px;background-color: transparent;border: 1px solid var(--textLightColor);border-radius: 50%;top: 50%;left: 0;transform: translateY(-50%); transition: all 0.3s ease;}
.form-checkbox .form-checkbox-label:after{content:'\f00c';position: absolute; font-family: 'Font Awesome 5 Pro';top: 50%;left: 0;transform: translateY(-50%);font-weight: 300;font-size:14px;width: 20px;height: 20px;display: flex;align-items: center;justify-content: center;padding-top: 2px;color: var(--textColor);opacity: 0;transition: all 0.3s ease;}
.form-checkbox .form-checkbox-input:checked ~ .form-checkbox-label:before{border-color:var(--colorSuccess);background-color:var(--colorSuccess);}
.form-checkbox .form-checkbox-input:checked ~ .form-checkbox-label:after{opacity: 1;}

.file-upload{position: relative; overflow: hidden;}
.file-upload .file-upload-input{position: absolute; top: 0; right: 0; width: calc(100% + 200px); height: 100%; opacity: 0; cursor: pointer; z-index: 1;}
.file-upload .file-upload-label{border-radius: 0;box-shadow: none;border:0;border-bottom: 2px solid var(--textDarkColor);color: var(--textDarkColor);background-color: transparent;min-width: 50px;min-height: 40px;width: 100%;padding: 6px 0;font-family: inherit;font-size: 14px; font-weight: 600; display: flex; align-items: center;}



.btn-action-success{font-size: 14px;text-transform: uppercase;font-weight: 600;background-color: transparent;border: 0;height: 36px;display: flex;justify-content: center;align-items: center;padding: 0;color: var(--colorSuccess);border-bottom: 2px solid var(--colorSuccess); border-radius:0;}
.btn-action-danger{font-size: 14px;text-transform: uppercase;font-weight: 600;background-color: transparent;border: 0;height: 36px;display: flex;justify-content: center;align-items: center;padding: 0;color: var(--colorDanger);border-bottom: 2px solid var(--colorDanger); border-radius:0;}
.btn-action-primary{font-size: 14px;text-transform: uppercase;font-weight: 600;background-color: transparent;border: 0;height: 36px;display: flex;justify-content: center;align-items: center;padding: 0;color: var(--color03);}
.btn-action-secondary{font-size: 14px;text-transform: uppercase;font-weight: 600;background-color: transparent;border: 0;height: 36px;display: flex;justify-content: center;align-items: center;padding: 0;color: var(--textLightColor);border-bottom: 2px solid var(--textLightColor); border-radius:0;}
.btn-sso{background-color: var(--color02);border: 0; color: var(--sidebarText) !important;}

.custom-checkbox{position: relative;padding-left: 24px;margin: 3px 0;display: inline-block;}
.custom-checkbox .custom-checkbox-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.custom-checkbox .custom-checkbox-label{position: relative;margin: 0;line-height: 16px;font-size: 14px;}
.custom-checkbox .custom-checkbox-label:before{content:'';position:absolute;width: 16px;height: 16px;background: var(--textColor);border: 1px solid #ddd;top: 50%;left: -24px; transform: translateY(-50%);border-radius: 2px;}
.custom-checkbox .custom-checkbox-label:after{content: "\f00c";position: absolute;font-family: "Font Awesome 5 Pro";width: 16px;height: 16px;top: 50%;left: -24px;transform: translateY(-50%); font-weight: 500;font-size: 10px;display: none;color: var(--textColor);text-align: center;}
.custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:after{display:block;}
.custom-checkbox .custom-checkbox-input:checked ~ .custom-checkbox-label:before{background: var(--color03);border: 1px solid var(--color03);}

.custom-radio{position: relative;padding-left: 24px;margin: 3px 0;display: inline-block;}
.custom-radio .custom-radio-input{position: absolute;z-index: 1;top: 0;left: 0;width: 100%;height: 100%;opacity: 0; cursor: pointer;}
.custom-radio .custom-radio-label{position: relative;margin: 0;line-height: 16px;font-size: 14px;}
.custom-radio .custom-radio-label:before{content:'';position:absolute;width: 16px;height: 16px;background: var(--textColor);border: 1px solid #ddd;top: 50%;left: -24px; transform: translateY(-50%);border-radius: 50%;}
.custom-radio .custom-radio-label:after{content: "\f111";position: absolute;font-family: "Font Awesome 5 Pro";width: 16px;height: 16px;top: 50%;left: -24px;transform: translateY(-50%); font-weight: 600;font-size: 8px;display: none;color: var(--textColor);text-align: center;}
.custom-radio .custom-radio-input:checked ~ .custom-radio-label:after{display:block;}
.custom-radio .custom-radio-input:checked ~ .custom-radio-label:before{background: var(--color03);border: 1px solid var(--color03);}

.checkbox-dropdown{position: relative;}
.checkbox-dropdown .dropdown-toggle{border-radius: 0;box-shadow: none;border: 0;border-bottom: 2px solid var(--textDarkColor);color: var(--textDarkColor);background-color: transparent;min-width: 50px;min-height: 40px;width: 100%;padding: 6px 0;font-family: inherit;font-size: 14px;font-weight: 600;text-align: left;}
.checkbox-dropdown .dropdown-menu{width: 100%;border: 0;box-shadow: rgb(0 0 0 / 10%) 0 2px 10px;}
.checkbox-dropdown .dropdown-menu .dropdown-wrap{max-height: 180px; overflow: auto;}
.checkbox-dropdown .dropdown-menu .dropdown-wrap::-webkit-scrollbar{width: 5px;height: 5px;background-color: var(--textColor);}
.checkbox-dropdown .dropdown-menu .dropdown-wrap::-webkit-scrollbar-thumb{background-color: var(--color03);}
.checkbox-dropdown .dropdown-menu .dropdown-item{padding: 0;}
.checkbox-dropdown .dropdown-menu .custom-checkbox{width: 100%;margin: 0;padding: 8px 20px;padding-left: 34px;}
.checkbox-dropdown .dropdown-menu .custom-checkbox .custom-checkbox-label{color: var(--textDarkColor);}
.checkbox-dropdown .dropdown-menu .dropdown-footer{display: flex;justify-content: center;align-items: center;padding:0 10px;border-top: 1px solid rgba(0,0,0,0.1);}
.checkbox-dropdown .dropdown-menu .dropdown-footer .btn-dropdown-action{font-size: 14px;text-transform: uppercase;font-weight: 600;background-color: transparent;border: 0;height: 36px;display: flex;justify-content: center;align-items: center;padding: 0;color: var(--color03);border-bottom: 2px solid var(--color03);border-radius: 0;}



.select2{width:100% !important;}
.select2-container .select2-selection--single, .select2-container .select2-selection--multiple{height: 40px;border: 1px solid var(--textDarkColor);border-radius: 0 !important;padding: 0;border-width: 0 0 2px 0;background-color: transparent;}
.select2-container--default .select2-selection--single .select2-selection__rendered,.select2-container--default .select2-selection--multiple .select2-selection__rendered{line-height: 40px;font-size: 14px;color: var(--textDarkColor);padding: 0;font-weight: 600; }
.select2-container--default .select2-selection--single .select2-selection__arrow,.select2-container--default .select2-selection--multiple .select2-selection__arrow{height: 36px;}
.select2-container--default .select2-search--dropdown .select2-search__field{border: 1px solid var(--textDarkColor);font-size: 14px;color: #6e707e;border-radius: 2px;}
.select2-container--default.select2-container--focus .select2-selection--multiple{border: 1px solid var(--textDarkColor);}
.select2-container--default .select2-search--inline .select2-search__field{color:var(--textDarkColor); margin: 0;}
.select2-dropdown{border: 0;border-radius: 0 !important;overflow: hidden;margin: 0;box-shadow: rgba(0,0,0,0.1) 0 2px 5px;}
.select2-container--default .select2-results__option--highlighted[aria-selected],.select2-container--default .select2-results__option[aria-selected=true]{background: var(--color03); color:var(--textColor);}
.select2-results__option{font-size: 14px;letter-spacing: 0;color: var(--textDarkColor);font-weight: 500;padding: 6px 12px;line-height: 1.2;}
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar{width: 5px;height: 5px;background-color: var(--textColor);}
.select2-container--default .select2-results>.select2-results__options::-webkit-scrollbar-thumb{background-color: var(--color03);}
.select2-container .select2-selection--multiple{height: auto;min-height: 40px;line-height: 40px;display: flex;padding: 4px;}
.select2-container--default .select2-selection--multiple .select2-selection__rendered{display: flex;flex-wrap: wrap; color: var(--textColor);}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li{line-height: 24px;margin: 2px;padding: 0;overflow: hidden;font-size: 12px;position: relative;}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove{width: 18px;padding: 0 4px;margin: 0;color: var(--textColor);background: rgba(0,0,0,0.2);text-align: center;font-size: 14px;font-weight: 600;position: absolute;top: 0;left: 0;height: 100%;}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover{color: var(--textColor);background: rgba(255,255,255,0.2);}
.select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice{background-color: var(--color03);border:none;padding-left: 24px;padding-right: 6px;}
.select2-container--default .select2-results__option[aria-disabled=true]{color: var(--textLightColor);}
.select2-container--default .select2-selection--single .select2-selection__arrow b{display: none;}
.select2-container--default .select2-selection--single .select2-selection__arrow:before{content: "\f078";font-family: "Font Awesome 5 Pro";position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);font-weight: 400;font-size: 14px;color: var(--textDarkColor);}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow:before{transform: translate(-50%,-50%) rotate(180deg);}

.custom-tab{}
.custom-tab .custom-tab-navigation{padding-left: 0;list-style: none;display: flex;flex-wrap: wrap;justify-content: center;margin: 0 auto 10px;}
.custom-tab .custom-tab-navigation > li{width: auto;margin:5px;position: relative;}
.custom-tab .custom-tab-navigation > li > a{background-color: rgba(255,255,255,0.1);color: #fff;padding: 10px 20px;display: block;font-size: 16px;font-weight: 400;text-transform: capitalize;position: relative;border-radius: 4px;max-width: 300px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;}
.custom-tab .custom-tab-navigation > li.active > a,.custom-tab .custom-tab-navigation > li > a:hover{background-color: var(--color03);}
.custom-tab .custom-tab-content{}
.custom-tab .custom-tab-content .custom-tab-wrap{display: none;background: transparent;padding: 20px 0;}
.custom-tab .custom-tab-content .custom-tab-wrap.active{display: block;}
.custom-tab .custom-tab-content .custom-tab-navigation > li > a{padding: 5px 10px;font-size: 14px;font-weight: 600;}
.custom-tab .custom-tab{margin-top: -20px;}
.custom-tab .custom-tab .custom-tab-navigation{margin-bottom: 0;}
@media (min-width: 576px){.custom-tab .custom-tab-navigation{max-width:620px}}
@media (min-width: 768px){.custom-tab .custom-tab-navigation{max-width:740px}}
@media (min-width: 992px){.custom-tab .custom-tab-navigation{max-width:1140px}}
@media (min-width: 1200px){.custom-tab .custom-tab-navigation{max-width:1200px}}


/* /////////////////////////////////////////////////////////////// */
/*             			   	 	BOOTSTRAP FIX            			   				 */
/* /////////////////////////////////////////////////////////////// */
@media (min-width: 576px){
	.container {max-width: none;}
	.modal-dialog {max-width: 700px;}
	.modal-sm {max-width: 360px;}
}
@media (min-width: 768px){
	.container {max-width: 740px;}
}
@media (min-width: 992px){
	.container {max-width: 960px;}
}
@media (min-width: 1200px){
	.container {max-width: 1170px;}
}
@media (min-width: 1440px){
	.container {max-width: 1340px;}
}
@media (min-width: 1600px){
	.container {max-width: 1400px;}
}
@media (min-width: 1900px){
	.container {max-width: 1540px;}
}

.modal-open {overflow: auto;}
.modal{ overflow-x: hidden; overflow-y: auto;}
.overflow-hidden{overflow:hidden !important;}
.dropdown .dropdown-toggle{padding-right: 16px !important;position: relative;}
.dropdown .dropdown-toggle::after{content: "\f078";font-family: "Font Awesome 5 Pro";font-size: 14px;font-weight: 500;display: block;position: absolute;margin: 0;vertical-align: unset;top: 50%;right: 0;transform: translateY(-50%);border: 0; color: var(--primaryColor); opacity: 1; margin-top: 2px; transition: all 0.3s ease;}
.dropdown.show .dropdown-toggle::after{transform: translateY(-50%) rotate(-180deg);}
.dropdown .dropdown-menu{display:block;top: 100% !important;left: 50% !important;transform: translate(-50%,50px) !important;visibility: hidden;opacity:0;transition:.3s ease all;}
.dropdown .dropdown-menu.show{display:block;transform: translate(-50%,0) !important;visibility: visible;opacity:1;transition:.3s ease all;}
.dropdown-menu .dropdown-item{display: flex;padding: 5px 15px;color: var(--secondaryColor);}
.dropdown-menu .dropdown-item:focus,.dropdown-menu .dropdown-item:hover,.dropdown-menu .dropdown-item:active{background: var(--primaryColor);color: #fff;}
.mega-dropdown{position:static !important;}
.mega-dropdown .mega-dropdown-menu{display:block;width: 100%;top: auto !important;left: 50% !important;transform: translate(-50%,50px) !important;padding: 20px 40px;visibility: hidden;opacity:0;transition:.5s ease all;}
.mega-dropdown .mega-dropdown-menu.show{display:block;transform: translate(-50%,0) !important;visibility: visible;opacity:1;transition:.5s ease all;}

/*////////////////////////////////////////////////////////////////*/
/*             		          	 HOME PAGE      	      				  	*/
/*////////////////////////////////////////////////////////////////*/

/*
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Condensed', sans-serif;
font-family: 'Source Sans Pro', sans-serif;
*/

.main-layout{background-color: var(--lightColor);width: 100%;min-height: 100vh;display: flex;}
.aside-layout{position: fixed; top: 0; left: 0; z-index: 9;background-color: var(--color02);width: 240px;min-width: 240px; height: 100vh; transition: all 0.3s ease;}

.brand-logo{display: flex;justify-content: center;align-items: center;padding: 20px 0; margin:0; background-color: var(--color01);}
.brand-logo .logo{display: flex;height: 40px;max-width: 100%;padding: 0;transition: all 0.3s ease;}
.brand-logo .logo img{width: 100%;height: 100%;object-fit: contain;}
.side-panel{position: relative;max-height: calc(100vh - 121px);overflow: auto;}
.side-panel .side-menu{position: relative;}
.side-panel .side-menu .menu-link{display: flex;align-items: center;padding: 0 20px;line-height: 1;height: 50px;position: relative;}
.side-panel .side-menu .menu-link .link-shade{position: absolute;width: 100%; height: 100%; background:var(--sidebarShade); top: 0; left: 0;z-index: -1; opacity: 0;transition: all 0.3s ease;}
.side-panel .side-menu .menu-link .link-icon{font-size: 18px;font-weight: 400;color: var(--sidebarText); width: 36px; height: 36px; display: flex; justify-content: center; align-items: center; border-radius: 6px;transition: all 0.3s ease;}
.side-panel .side-menu .menu-link .link-text{display: block; font-size: 14px; text-transform: uppercase; margin-left: 10px;font-weight: 300;color: var(--sidebarText); transition: all 0.3s ease;}


.side-panel .side-menu .menu-link.active{box-shadow: inset 0 0 7px 0 rgba(0,0,0,0.4);}
.side-panel .side-menu .menu-link.active .link-shade{opacity: 1;}
.side-panel .side-menu .menu-link.active .link-icon{color: var(--color03);}
.side-panel .side-menu .menu-link.active .link-icon i{font-weight:500;}
.side-panel .side-menu .menu-link.active .link-text{color: var(--color03);font-weight:500;}
.side-panel .side-menu .menu-link+.menu-link{margin-top:10px;}

.content-layout{width: 100%; max-width: calc(100% - 240px); margin-left: 240px; transition: all 0.3s ease;}
.header-layout{position: sticky; top: 0; display: flex;align-items: center;justify-content: space-between;padding: 0 20px 0 0;width: 100%;background-color: var(--color01);z-index: 9; height: 80px;}
.header-layout .sidepanel-toggle{width: 48px;height: 48px;display: none;justify-content: center;align-items: center;border: 0;background-color: transparent;padding: 0;line-height: 1;font-size: 24px;color: var(--color03);}
.header-layout .user-menu{margin-left: auto;}
.header-layout .user-menu .dropdown-toggle{display: flex;align-items: center;background-color: transparent;border: 0; text-align: left;padding-right: 24px !important;}
.header-layout .user-menu .dropdown-toggle .image{width: 40px;height: 40px;border-radius: 50%;display: flex;justify-content: center;align-items: center;overflow: hidden;margin-right: 16px;}
.header-layout .user-menu .dropdown-toggle .image img{width: 100%;height: 100%;object-fit: cover;}
.header-layout .user-menu .dropdown-toggle .title{font-size: 13px;color: var(--topbarText);line-height: 1;font-weight: 400;}
.header-layout .user-menu .dropdown-toggle .title span{display: block;font-size: 16px;text-transform: capitalize;font-weight: 600;line-height: 1.4;color: var(--topbarText);}
.header-layout .user-menu .dropdown-toggle:after{color:var(--topbarText); font-weight: 300;}
.header-layout .user-menu .dropdown-menu{width: 100%;min-width:160px;margin: 10px 0 0;border: 0;box-shadow: rgba(0,0,0,0.05) 0 2px 20px;padding: 6px 0;border-radius: 6px;}
.header-layout .user-menu .dropdown-menu:before{content: '';position: absolute; top:-20px; left: 50%; transform: translateX(-50%); border: 10px solid transparent; border-bottom-color: #fff;}
.header-layout .user-menu .dropdown-menu .user-menu-item{display: flex;align-items: center;padding: 10px;line-height: 1;font-size: 14px;font-weight: 400;color: var(--textDarkColor);transition: all 0.3s ease; position: relative;}
.header-layout .user-menu .dropdown-menu .user-menu-item:after{content: ''; position: absolute;width: 100%; height: 100%; background-color: var(--color03); top: 0; left: 0;z-index: -1; opacity: 0;transition: all 0.3s ease;}
.header-layout .user-menu .dropdown-menu .user-menu-item:hover{color: var(--textColor);}
.header-layout .user-menu .dropdown-menu .user-menu-item:hover i{color: var(--textColor);}
.header-layout .user-menu .dropdown-menu .user-menu-item:hover:after{opacity: 1;}

.main-layout.collapsed .aside-layout{width: 76px;min-width: 76px;}
.main-layout.collapsed .aside-layout .brand-logo .logo{padding: 5px;}
.main-layout.collapsed .aside-layout .side-panel .side-menu .menu-link .link-text{display: none;}
.main-layout.collapsed .content-layout{max-width: calc(100% - 76px);margin-left: 76px;}
.main-layout .content-layout.full-width{max-width: 100%;margin: 0;}


.body-layout{position: relative;padding: 30px;}

.page-section{position: relative;}
.page-section .page-header{display: flex; align-items: center;margin: 0 0 20px;}
.page-section .page-header .page-title{font-size: 18px;color: var(--color03); font-weight: 600; text-transform: uppercase;}
.page-section .page-actions{}

.users{position: relative; padding:0;}
.users .users-list{position: relative;}
.users .users-list .item{display: flex;align-items: center; padding:20px; flex-wrap: wrap;border-radius: 5px;background-color: var(--textColor);box-shadow: rgba(0,0,0,0.1) 0 16px 15px;}
.users .users-list .item+.item{margin: 16px 0 0;}
.users .users-list .item .item-image{width: 40px;height: 40px;overflow: hidden;border-radius: 50%; margin-right: 40px;}
.users .users-list .item .item-image img{width: 100%;height: 100%;object-fit: cover;}
.users .users-list .item .item-user{width:100%; max-width:calc(50% - 160px);margin-right: 20px;}
.users .users-list .item .user-name{font-size: 16px; line-height: 1; font-weight: 600; color: var(--textDarkColor);}
.users .users-list .item .user-data{font-size: 13px; line-height: 1.2; font-weight: 600; color: var(--textDarkColor);word-break: break-word;}
.users .users-list .item .user-data span{color: var(--textLightColor); text-transform: uppercase;font-weight: 500;display: inline-flex; margin-right: 6px;}
.users .users-list .item .item-user-info{width:100%; max-width:calc(50% - 160px);margin-right: 20px;}
.users .users-list .item .item-status{width: 120px;margin-right: 20px;}
.users .users-list .item .status{text-transform: uppercase; font-size: 14px; font-weight: 600; display: flex; align-items: center;}
.users .users-list .item .status i{font-size: 16px;}
.users .users-list .item .status.active{color: var(--colorSuccess);}
.users .users-list .item .status.inactive{color: var(--colorDanger);}
.users .users-list .item .item-action{display: flex; justify-content: flex-end; margin-left: auto; width: 60px;}
.users .users-list .item .action-dropdown{}
.users .users-list .item .action-dropdown .dropdown-toggle{background-color: transparent;border: 0;padding: 0;font-size: 16px;font-weight: 400;color: var(--textDarkColor);}
.users .users-list .item .action-dropdown .dropdown-toggle:after{display: none;}
.users .users-list .item .action-dropdown .dropdown-menu{margin: 10px 0 0;border: 0;box-shadow: rgba(0,0,0,0.05) 0 2px 20px;padding: 6px 0;border-radius: 6px;}
.users .users-list .item .action-dropdown .dropdown-item{display: flex;align-items: center;padding: 10px;line-height: 1;font-size: 14px;font-weight: 400;color: var(--textDarkColor);transition: all 0.3s ease; position: relative;}
.users .users-list .item .action-dropdown .dropdown-item:hover{background-color: var(--color03);color: #fff;}
.users .users-list .item .cards{margin: 0;width: 100%;max-width: 100%;}
.users .users-list .item .cards+.cards{border-top:1px solid var(--textLightColor);}
.users .users-list .item .cards .cards-body{box-shadow: none; padding-left: 0; padding-right: 0;}


.cards{position: relative; margin: 0 0 40px;}
.cards .cards-header{display: flex; justify-content: flex-start; align-items: center; padding: 10px 0;}
.cards .cards-header .cards-title{font-size: 18px;color: var(--color03);font-weight: 600;text-transform: uppercase;}
.cards .cards-body{position: relative; background-color: var(--textColor); border-radius: 5px;box-shadow: rgba(0,0,0,0.1) 0 16px 15px; padding: 20px;}

.profile-image{position: relative;width: 100%;background-color: var(--textColor);border-radius: 5px;box-shadow: rgba(0,0,0,0.1) 0 16px 15px; overflow: hidden;}
.profile-image .image-title{font-size: 13px;text-transform: uppercase;font-weight: 600;color: var(--textLightColor);margin: 0 0 4px;}
.profile-image .image-wrap{position: relative;width: 100%; height: 240px; background-color: var(--white); padding: 5px;}
.profile-image .image-wrap img{width: 100%; height: 100%; object-fit: contain;}
.profile-image .image-upload{position: relative; overflow: hidden;border-top:1px solid rgba(0,0,0,0.1);}
.profile-image .image-upload .image-upload-input{position: absolute; top: 0; right: 0; width: 400px; height: 100%; opacity: 0; z-index: 1; cursor: pointer;}
.profile-image .image-upload .image-upload-label{display: flex;justify-content: center;align-items: center;padding: 0 10px;height: 40px;background-color: var(--white);text-transform: uppercase;font-weight: 600;color: var(--color03);}

.setting-image{position: relative;width: 140px;}
.setting-image .image-title{font-size: 13px;text-transform: uppercase;font-weight: 600;color: var(--textLightColor);margin: 0 0 4px;}
.setting-image .image-wrap{position: relative;width: 100%; height: 140px; background-color: var(--white); padding: 5px;}
.setting-image .image-wrap img{width: 100%; height: 100%; object-fit: contain;}
.setting-image .image-upload{position: relative; overflow: hidden;border-top:1px solid rgba(0,0,0,0.1);}
.setting-image .image-upload .image-upload-input{position: absolute; top: 0; right: 0; width: 400px; height: 100%; opacity: 0; z-index: 1; cursor: pointer;}
.setting-image .image-upload .image-upload-label{display: flex;justify-content: center;align-items: center;padding: 0 10px;height: 40px;background-color: var(--white);text-transform: uppercase;font-weight: 600;color: var(--color03); font-size: 13px;}


.preview{position: relative; width: 100%; border: 2px solid var(--textDarkColor);}
.preview .preview-header{display: flex; align-items: center; justify-content: space-between; background-color: var(--color01); padding: 10px 20px; height: 40px;}
.preview .preview-header .preview-header-title{font-size: 15px; font-weight: 600; text-transform: uppercase; line-height: 1; color: var(--textColor);}
.preview .preview-header .preview-header-close{width: 12px; height: 12px; border-radius: 50%; background-color:  var(--textColor);}
.preview .preview-body{display: flex; height: 380px;}
.preview .preview-sidebar{width: 120px;background-color:  var(--color02); padding: 10px 20px;}
.preview .preview-sidebar .preview-lines{width: 100%; height: 12px; background-color: var(--textColor);margin: 10px 0;}
.preview .preview-sidebar .preview-lines.active{background-color: var(--color03);}
.preview .preview-content{width: 100%; max-width: calc(100% - 120px); padding: 10px 20px;}
.preview .preview-content .preview-lines{width: 100%; height: 12px; background-color: var(--textLightColor);margin: 10px 0;}
.preview .preview-content .preview-lines.active{background-color: var(--color03);}
.preview .preview-content .preview-lines.lines-sm{max-width: 120px;}
.preview .preview-content .preview-space{margin: 40px 0;}



.setting-colors{position: relative;}
.setting-colors .item{display: flex; align-items: center; padding: 15px 0;}
.setting-colors .item .selected-setting{display: flex; align-items: center;width: 100%; max-width: calc(100% - 180px);}
.setting-colors .item .selected-setting .selected-setting-color{width: 40px; height: 40px; border-radius: 50%; margin-right: 16px;}
.setting-colors .item .selected-setting .selected-setting-content{width: 100%; max-width: calc(100% - 56px);}
.setting-colors .item .selected-setting .color-location{font-size: 16px; font-weight: 600; color: var(--textDarkColor);}
.setting-colors .item .selected-setting .color-code{font-size: 13px; font-weight: 500; color: var(--textLightColor);}
.setting-colors .item .selected-setting-action{width: 160px; margin-left: 20px;}
.setting-colors .item .change-color{width: 100%; position: relative; overflow: hidden;}
.setting-colors .item .change-color .change-color-input{position: absolute; top: 0; right: 0; width:100%; height: 100%; opacity: 0; z-index: 1; cursor: pointer;}
.setting-colors .item .change-color .change-color-label{display: flex;justify-content: center;align-items: center;padding: 0 10px;height: 40px;background-color: var(--white);text-transform: uppercase;font-weight: 600;color: var(--color03);font-size: 13px;}

.setting-actions{display:flex; justify-content: flex-end; align-items: center; padding: 10px 0 0; margin: 40px 0 0; border-top: 1px solid var(--textLightColor);}


.authentication{position: relative; width: 100%; height: calc(100vh - 60px); min-height: 600px; display: flex; justify-content: center; align-items: center;}
.authentication .authentication-card{position: relative; overflow: hidden; border-radius: 8px; background-color: var(--white); box-shadow: rgba(0,0,0,0.1) 0 16px 15px; width: 100%; max-width: 440px;}
.authentication .authentication-card .authentication-card-header{position: relative;}
.authentication .authentication-card .authentication-card-body{padding:40px;}
.authentication .authentication-card .authentication-card-title{font-size: 18px;font-weight: 600;text-transform: uppercase;margin: 0 0 20px;text-align: center;color: var(--color03);}
.authentication .authentication-card .forget-password{font-size: 16px;font-weight: 600;}
.pagination{display: flex;align-items: center; padding: 0;margin: 20px 0 0;border-radius: 0;}
.pagination .page-number{margin-right: auto;font-size: 14px;text-transform: uppercase;color: var(--textDarkColor);font-weight: 600;}
.pagination .page-number span{color: var(--textLightColor);}
.pagination .page-item{width: 32px;height: 32px;display: flex;justify-content: center;align-items: center;border-bottom: 2px solid transparent;}
.pagination .page-link{position: relative;display: block; background-color: transparent;border: 0;color: var(--textDarkColor);font-weight: 600;font-size: 14px;}
.pagination .page-prev{}
.pagination .page-next{}
.pagination .page-item.active{border-color: var(--color03);}
.pagination .page-item.active .page-link{background-color: transparent;color: var(--textDarkColor);}

/* ********|| MODAL STARTS ||******** */
.modal-content{border-radius:6px; border: 0; box-shadow: rgba(0,0,0,0.4) 0 2px 30px;}
.modal-close{position: absolute;top: 10px;right: 10px;font-size: 20px;color: var(--textLightColor);width: 24px;height: 24px;display: flex;justify-content: center;align-items: center;padding: 0;cursor: pointer;z-index: 1;border: 0;background-color: transparent;}
.modal-header{border-color:var(--textLightColor);}
.modal-header .modal-title{font-size: 17px;font-weight: 600;text-transform: uppercase;color: var(--color03);}
.modal-body{padding: 30px;}
.modal-text{font-size: 15px;font-weight: 500;text-align: center;line-height: 1.5;color: var(--textDarkColor);}
.modal-footer{padding: 5px 20px 10px;border-color:var(--textLightColor);}

/* ********|| MODAL ENDS ||******** */

.nav-tabs{border: 0;}
.nav-tabs .nav-item+.nav-item{margin-left: 10px;}
.nav-tabs .nav-item .nav-link{border: 0;font-weight: 600;font-size: 14px;line-height: 1;display: flex;justify-content: center;align-items: center;padding: 0 16px 6px;height: 46px;border-radius: 8px 8px 0 0;background-color: transparent;color: var(--textDarkColor);margin: 0 0 -5px;text-transform: uppercase;}
.nav-tabs .nav-item .nav-link.active{box-shadow: rgb(0 0 0 / 10%) 0 16px 15px;background-color: var(--textColor);color: var(--color03);}

.nav.nav-pills{border-bottom: 1px solid rgba(0,0,0,0.1);}
.nav.nav-pills .nav-item+.nav-item{margin-left: 20px;}
.nav.nav-pills .nav-item .nav-link{font-size: 14px;font-weight: 600;display: flex;justify-content: center;align-items: center;padding: 0;height: 40px;color: var(--textDarkColor); border-radius: 0;position: relative;}
.nav.nav-pills .nav-item .nav-link:after{content:''; position: absolute; bottom: 0;left: 0; width: 100%; border-bottom: 3px solid var(--color03);opacity: 0;}
.nav.nav-pills .nav-item .nav-link.active{background-color: transparent;color: var(--color03);}
.nav.nav-pills .nav-item .nav-link.active:after{opacity: 1;} 

.sec-title{font-size: 16px;font-weight: 600;color: var(--color03);margin: 0 0 10px;text-transform: uppercase;}
.instruction-text{color:var(--textLightColor);font-size: 13px;font-weight: 400;line-height: 1.4;margin: 6px 0 0;}
/* ********|| RESPONSIVE STARTS ||******** */

@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1440px) {

}
@media screen and (max-width: 1366px) {

}
@media screen and (max-width: 1280px) {
	
}
@media screen and (max-width: 1024px) {
	
}
@media screen and (max-width: 990px) {

}
@media screen and (max-width: 990px) and (orientation: landscape){

}
@media screen and (max-width: 767px) {
	
}
@media screen and (max-width: 767px)  and (orientation: landscape){

}
@media screen and (max-width: 600px) {

}
@media screen and (max-width: 480px) {

	
}
@media screen and (max-width: 360px) {

}
@media screen and (max-width: 320px) {

}
/* ********|| RESPONSIVE ENDS ||******** */

